import ApplicationController from 'controllers/application_controller'
import Cookies from 'vendor/js.cookie'

export default class extends ApplicationController {

  connect() {
    this.reportEvent('popover-ready')
  }

  reportEvent(event, object = null) {
    if (this.embedded) {
      this.embedded.reportWidgetEvent(event, object)
    }
  }

  openPopover () {
    this.reportEvent('popover-open')

    if (this.embedded) {
      this.embedded.requestParentViewportDimensions()
      this.embedded.reportSize()
    }
  }

  closePopover (event) {
    this.reportEvent('popover-close')

    if (event) {
      event.preventDefault()
    }
  }

  navigateToPage (event) {
    let { params: { page } } = event

    this.reportEvent('popover-navigateToPage', page)
  }

  navigateToAnchor (event) {
    let { params: { anchor } } = event

    this.reportEvent('popover-navigateToAnchor', anchor)

    if (event) {
      event.preventDefault()
    }
  }

  finish (event) {
    let { params: { url } } = event

    this.reportEvent('popover-finish', url)
  }

  reportEvent(event, object = null) {
    if (this.embedded) {
      this.embedded.reportWidgetEvent(event, object)
    }
  }
}
