import ApplicationController from 'controllers/application_controller'
import BookableController from 'controllers/realtime_widget/bookable_controller'
import _ from 'underscore'

export default class extends ApplicationController {

  static values = {
    needsSelection: Boolean
  }

  static targets = ['input']

  static classes = ['disabled', 'hidden']

  static outlets = ['realtime-widget', 'realtime-widget--bookable']

  /**
   * @returns {RealtimeWidgetController}
   */
  get widget() {
    return this.realtimeWidgetOutlet
  }

  /**
   * @returns {BookableController[]}
   */
  get bookables() {
    return this.realtimeWidgetBookableOutlets
  }

  /**
   * @returns {BookableController}
   */
  get booking() {
    return this.bookables.find((bookable) => bookable.isSelected)
  }

  get value() {
    return this.booking.idValue
  }

  get label() {
    return this.booking.labelValue
  }

  get selectedBookableInput() {
    return this.inputTargets.find((input) => input.checked)
  }

  get selectedBookableId() {
    return Number(this.selectedBookableInput.value)
  }

  get hasSelectedBookable() {
    return this.selectedBookableId !== 0
  }

  get needsSelection() {
    if (this.bookables.length <= 1 || !this.needsSelectionValue) {
      return false
    }

    return !this.hasSelectedBookable
  }

  connect () {
    this.selectBookable()
  }

  changed() {
    this.selectBookable()
  }

  selectBookable(newBookableId = this.selectedBookableId) {
    if (newBookableId != this.selectedBookableId) {
      this.inputTargets.forEach((input) => {
        input.checked = parseInt(input.value) == newBookableId
      })
    }

    this.bookables.forEach((bookable) => {
      if (bookable.idValue === newBookableId) {
        bookable.select()
      } else {
        bookable.unselect()
      }
    })
  }

  refreshBookables () {
    this.bookables.forEach((bookable) => bookable.toggleVisibilityAndAvailability())
    this.toggleVisibility()
  }

  toggleVisibility () {
    const offers = this.bookables.filter((bookable) => bookable.isOffer);

    const allOffersOptionalAndHidden = offers.every((bookable) => {
      return bookable.isOffer && bookable.isHidden
    });

    if (allOffersOptionalAndHidden) {
      this.element.classList.add(this.hiddenClass)
    } else if (this.element.classList.contains(this.hiddenClass)) {
      this.element.classList.remove(this.hiddenClass)
    }
  }
}
